var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":"","dense":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"4","lg":"4","md":"6","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.searchTerm = null}}},[_c('v-icon',[_vm._v("refresh")])],1),_c('ExportTableContractSchedule',{staticClass:"ml-2",attrs:{"export-params":_vm.exportContractScheduleParams}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.items,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"appic-table-light specification-table",attrs:{"fixed-header":true,"footer-props":{
            itemsPerPageOptions: [20,50,75,-1],
            showCurrentPage: true,
            showFirstLastPage: true
        },"headers":_vm.headers,"height":_vm.tableHeight,"hide-default-footer":true,"items-per-page":-1,"items":_vm.items,"search":_vm.searchTerm,"sort-by":['Invoice.no'],"calculate-widths":"","dense":"","id":"contractScheduleTable","item-key":"Contract.id"},scopedSlots:_vm._u([{key:"item.Contract.title",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Contract.title))])]}},{key:"item.Invoice.no",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.Invoice.no))])]}},{key:"item.Invoice.date",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.Invoice.date != '0000-00-00' ? _vm.formatDate(item.Invoice.date) : '')+" ")]}},{key:"item.Contract.etddate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.Contract.etddate != '0000-00-00' ? _vm.formatDate(item.Contract.etddate) : '')+" ")]}},{key:"item.Contract.etadate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.Contract.etadate != '0000-00-00' ? _vm.formatDate(item.Contract.etadate) : '')+" ")]}},{key:"item.Contract.bldate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.Contract.bldate != '0000-00-00' ? _vm.formatDate(item.Contract.bldate) : '')+" ")]}},{key:"item.Contract.amount",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatThisNumber(item.Contract.amount,'0,0.00')))])]}},{key:"item.Invoice.forex",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatThisNumber(item.Invoice.forex,'0,0.00000')))])]}},{key:"item.Contract.amount_converted",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatThisNumber(item.Contract.amount_converted,'0,0.00')))])]}},{key:"item.Contract.cost",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatThisNumber(item.Contract.cost,'0,0.00')))])]}},{key:"item.CustomerPayment.deposit",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatThisNumber(item.CustomerPayment.deposit,'0,0.00')))])]}},{key:"item.CustomerPayment.outstanding",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatThisNumber(item.CustomerPayment.outstanding,'0,0.00')))])]}},{key:"item.SupplierPayment.deposit",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatThisNumber(item.SupplierPayment.deposit,'0,0.00')))])]}},{key:"item.SupplierPayment.outstanding",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.formatThisNumber(item.SupplierPayment.outstanding,'0,0.00')))])]}},{key:"item.CustomerPayment.paymentdate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.CustomerPayment.paymentdate != '0000-00-00' ? _vm.formatDate(item.CustomerPayment.paymentdate) : '')+" ")]}},{key:"item.SupplierPayment.paymentdate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.SupplierPayment.paymentdate != '0000-00-00' ? _vm.formatDate(item.SupplierPayment.paymentdate) : '')+" ")]}},{key:"item.CustomerPayment.duedate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.CustomerPayment.duedate != '0000-00-00' ? _vm.formatDate(item.CustomerPayment.duedate) : '')+" ")]}},{key:"item.SupplierPayment.duedate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.SupplierPayment.duedate != '0000-00-00' ? _vm.formatDate(item.SupplierPayment.duedate) : '')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }