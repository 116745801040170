<template>
    <div class="mt-3">
        <v-sheet class="dense-inputs">
            <v-row no-gutters dense>
                <v-col cols="4" lg="4" md="6" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTableContractSchedule
                        :export-params="exportContractScheduleParams"
                        class="ml-2"
                    ></ExportTableContractSchedule>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.items"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :fixed-header="true"
            :footer-props="{
                itemsPerPageOptions: [20,50,75,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :height="tableHeight"
            :hide-default-footer="true"
            :items-per-page="-1"
            :items="items"
            :search="searchTerm"
            :sort-by="['Invoice.no']"
            calculate-widths
            class="appic-table-light specification-table"
            dense
            id="contractScheduleTable"
            item-key="Contract.id"
        >
            <template v-slot:item.Contract.title="{ item }">
                <span class="font-weight-bold">{{ item.Contract.title }}</span>
            </template>
            <template v-slot:item.Invoice.no="{ item }">
                <span class="text-no-wrap">{{ item.Invoice.no }}</span>
            </template>
            <template v-slot:item.Invoice.date="{ item }">
                {{ item.Invoice.date != '0000-00-00' ? formatDate(item.Invoice.date) : '' }}
            </template>
            <template v-slot:item.Contract.etddate="{ item }">
                {{ item.Contract.etddate != '0000-00-00' ? formatDate(item.Contract.etddate) : '' }}
            </template>
            <template v-slot:item.Contract.etadate="{ item }">
                {{ item.Contract.etadate != '0000-00-00' ? formatDate(item.Contract.etadate) : '' }}
            </template>
            <template v-slot:item.Contract.bldate="{ item }">
                {{ item.Contract.bldate != '0000-00-00' ? formatDate(item.Contract.bldate) : '' }}
            </template>
            <template v-slot:item.Contract.amount="{ item }">
                <span class="text-right">{{ formatThisNumber(item.Contract.amount,'0,0.00') }}</span>
            </template>
            <template v-slot:item.Invoice.forex="{ item }">
                <span class="text-right">{{ formatThisNumber(item.Invoice.forex,'0,0.00000') }}</span>
            </template>
            <template v-slot:item.Contract.amount_converted="{ item }">
                <span class="text-right">{{ formatThisNumber(item.Contract.amount_converted,'0,0.00') }}</span>
            </template>
            <template v-slot:item.Contract.cost="{ item }">
                <span class="text-right">{{ formatThisNumber(item.Contract.cost,'0,0.00') }}</span>
            </template>
            <template v-slot:item.CustomerPayment.deposit="{ item }">
                <span class="text-right">{{ formatThisNumber(item.CustomerPayment.deposit,'0,0.00') }}</span>
            </template>
            <template v-slot:item.CustomerPayment.outstanding="{ item }">
                <span class="text-right">{{ formatThisNumber(item.CustomerPayment.outstanding,'0,0.00') }}</span>
            </template>
            <template v-slot:item.SupplierPayment.deposit="{ item }">
                <span class="text-right">{{ formatThisNumber(item.SupplierPayment.deposit,'0,0.00') }}</span>
            </template>
            <template v-slot:item.SupplierPayment.outstanding="{ item }">
                <span class="text-right">{{ formatThisNumber(item.SupplierPayment.outstanding,'0,0.00') }}</span>
            </template>
            <template v-slot:item.CustomerPayment.paymentdate="{ item }">
                {{ item.CustomerPayment.paymentdate != '0000-00-00' ? formatDate(item.CustomerPayment.paymentdate) : '' }}
            </template>
            <template v-slot:item.SupplierPayment.paymentdate="{ item }">
                {{ item.SupplierPayment.paymentdate != '0000-00-00' ? formatDate(item.SupplierPayment.paymentdate) : '' }}
            </template>
            <template v-slot:item.CustomerPayment.duedate="{ item }">
                {{ item.CustomerPayment.duedate != '0000-00-00' ? formatDate(item.CustomerPayment.duedate) : '' }}
            </template>
            <template v-slot:item.SupplierPayment.duedate="{ item }">
                {{ item.SupplierPayment.duedate != '0000-00-00' ? formatDate(item.SupplierPayment.duedate) : '' }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {formatDate, numberFormat} from "Helpers/helpers";
import {api} from "Api";
import {mapActions, mapGetters} from "vuex";
import JsonExcel from "vue-json-excel"
import {mapFields} from "vuex-map-fields";

const ExportTableContractSchedule = () => import("Components/Appic/ExportTableContractSchedule");

export default {
    name: "ContractScheduleList",
    props: ['filters','load','office','salesType','contract-schedule-loaded','resetExportParam'],
    components: {ExportTableContractSchedule, JsonExcel},
    data() {
        return {
            items: [],
            loading: {
                filterResults: false,
                search: false,
                items: false
            },
            searchField: 'Contract.title', //default search field
            searchTerm: null,
            tableHeight: '500',
            tableOptions: {}
        }
    },
    computed: {
        ...mapFields('report',{
            exportContractScheduleParams: 'exportContractScheduleParams',
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies',
        }),
        ...mapGetters([
            'currencies',
            'offices'
        ]),
        exportFileName() {
            return this.office + '-' + this.salesType + '-contract-schedule.xls'
        },
        exportFileFields() {
            let fields = {}
            this.headers.forEach(header => {
                fields[header.text] = header.value
            })
            return fields
        },
        officeCurrency() {
            let officeId = 1
            switch (this.office){
                case 'ltd':
                    officeId = 3
                    break;
                case 'my':
                    officeId = 1
                    break;
                case 'id':
                    officeId = 21
                    break;
                case 'th':
                    officeId = 20
                    break;
                case 'vn':
                    officeId = 22
                    break;
            }
            let office = this.offices.find(o => o.Office.id == officeId)
            if(office){
                let currency = this.allCurrencies.find(c => c.Currency.id == office.Office.currency_id)
                if(currency){
                    return currency.Currency.code
                }
            }
            return 'USD'
        },
        formatDate: () => formatDate,
        headers() {
            let headers = []
            switch(this.salesType){
                case 'ds':
                    headers = [
                        {
                            id: 1,
                            text: this.$t('message.contractPo'),
                            value: 'Contract.title',
                            sortable: true,
                            searchable: true,
                            class: 'py-0 pr-0 pl-1 font-sm text-left'
                        },
                        {
                            id: 2,
                            text: this.$t('message.buyer'),
                            value: 'Customer.name',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 3,
                            text: this.$t('message.sales'),
                            value: 'Salescontact.name',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 4,
                            text: this.$t('message.terms'),
                            value: 'Contract.type_insurance',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 5,
                            text: this.$t('message.buyerTerms'),
                            value: 'CustomerPayment.status',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 6,
                            text: this.$t('message.invoice'),
                            value: 'Invoice.no',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 7,
                            text: this.$t('message.date'),
                            value: 'Invoice.date',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 8,
                            text: this.$t('message.cur'),
                            value: 'Currency.code',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 9,
                            text: this.$t('message.amount'),
                            value: 'Contract.amount',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell text-end'
                        },
                        {
                            id: 10,
                            text: this.$t('message.deposit'),
                            value: 'CustomerPayment.deposit',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell text-end'
                        },
                        {
                            id: 11,
                            text: this.$t('message.date'),
                            value: 'CustomerPayment.paymentdate',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 12,
                            text: this.$t('message.balance'),
                            value: 'CustomerPayment.outstanding',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell text-end'
                        },
                        {
                            id: 13,
                            text: this.$t('message.etd'),
                            value: 'Contract.etddate',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 14,
                            text: this.$t('message.eta'),
                            value: 'Contract.etadate',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        // {
                        //     id: 10,
                        //     text: this.$t('message.blDate'),
                        //     value: 'Contract.bldate',
                        //     sortable: true,
                        //     searchable: true,
                        //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                        // },
                        {
                            id: 15,
                            text: this.$t('message.dueDate'),
                            value: 'CustomerPayment.duedate',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        // {
                        //     id: 14,
                        //     text: this.$t('message.forex'),
                        //     value: 'Invoice.forex',
                        //     sortable: true,
                        //     searchable: true,
                        //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                        // },
                        // {
                        //     id: 15,
                        //     text: this.$t('message.invoiceValue') + " (MYR)",
                        //     value: 'Contract.amount_converted',
                        //     sortable: true,
                        //     searchable: true,
                        //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                        // },
                        {
                            id: 16,
                            text: this.$t('message.supplier'),
                            value: 'Supplier.name',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 17,
                            text: this.$t('message.terms'),
                            value: 'Contract.type_insurance',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 18,
                            text: this.$t('message.supplierTerms'),
                            value: 'SupplierPayment.status',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 19,
                            text: this.$t('message.invoice'),
                            value: 'Contract.supplier_invoice_no',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 20,
                            text: this.$t('message.date'),
                            value: 'Contract.supplier_invoice_date',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 21,
                            text: this.$t('message.cur'),
                            value: 'Currency.code',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 22,
                            text: this.$t('message.cost'),
                            value: 'Contract.cost',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell text-end'
                        },
                        {
                            id: 23,
                            text: this.$t('message.deposit'),
                            value: 'SupplierPayment.deposit',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell text-end'
                        },
                        {
                            id: 24,
                            text: this.$t('message.balance'),
                            value: 'SupplierPayment.outstanding',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell text-end'
                        },
                        {
                            id: 25,
                            text: this.$t('message.dueDate'),
                            value: 'SupplierPayment.duedate',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 26,
                            text: this.$t('message.paid'),
                            value: 'SupplierPayment.paymentdate',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                    ]
                    break
                case 'ds-ss':
                case 'ss':
                    headers = [
                        {
                            id: 1,
                            text: this.$t('message.contractPo'),
                            value: 'Contract.title',
                            sortable: true,
                            searchable: true,
                            class: 'py-0 pr-0 pl-1 font-sm text-left'
                        },
                        {
                            id: 2,
                            text: this.$t('message.buyer'),
                            value: 'Customer.name',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 3,
                            text: this.$t('message.sales'),
                            value: 'Salescontact.name',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 4,
                            text: this.$t('message.terms'),
                            value: 'Contract.type_insurance',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 5,
                            text: this.$t('message.buyerTerms'),
                            value: 'CustomerPayment.status',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 6,
                            text: this.$t('message.invoice'),
                            value: 'Invoice.no',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 7,
                            text: this.$t('message.date'),
                            value: 'Invoice.date',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 8,
                            text: this.$t('message.cur'),
                            value: 'Currency.code',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 9,
                            text: this.$t('message.amount'),
                            value: 'Contract.amount',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-end',
                            cellClass: 'green-cell text-end'
                        },
                        {
                            id: 10,
                            text: this.$t('message.deposit'),
                            value: 'CustomerPayment.deposit',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-end',
                            cellClass: 'green-cell text-end'
                        },
                        {
                            id: 11,
                            text: this.$t('message.date'),
                            value: 'CustomerPayment.paymentdate',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 12,
                            text: this.$t('message.balance'),
                            value: 'CustomerPayment.outstanding',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-end',
                            cellClass: 'green-cell text-end'
                        },
                        {
                            id: 13,
                            text: this.$t('message.etd'),
                            value: 'Contract.etddate',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        {
                            id: 14,
                            text: this.$t('message.eta'),
                            value: 'Contract.etadate',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        // {
                        //     id: 9,
                        //     text: this.$t('message.blDate'),
                        //     value: 'Contract.bldate',
                        //     sortable: true,
                        //     searchable: true,
                        //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                        // },
                        {
                            id: 15,
                            text: this.$t('message.dueDate'),
                            value: 'CustomerPayment.duedate',
                            sortable: true,
                            searchable: true,
                            class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'green-cell'
                        },
                        // {
                        //     id: 13,
                        //     text: this.$t('message.forex'),
                        //     value: 'Invoice.forex',
                        //     sortable: true,
                        //     searchable: true,
                        //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                        // },
                        // {
                        //     id: 14,
                        //     text: this.$t('message.invoiceValue') + " (" + this.officeCurrency + ")",
                        //     value: 'Contract.amount_converted',
                        //     sortable: true,
                        //     searchable: true,
                        //     class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                        // },
                        {
                            id: 16,
                            text: this.$t('message.supplier'),
                            value: 'Supplier.name',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 17,
                            text: this.$t('message.terms'),
                            value: 'Contract.type_insurance',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 18,
                            text: this.$t('message.supplierTerms'),
                            value: 'SupplierPayment.status',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 19,
                            text: this.$t('message.invoice'),
                            value: 'Contract.supplier_invoice_no',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 20,
                            text: this.$t('message.date'),
                            value: 'Contract.supplier_invoice_date',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 21,
                            text: this.$t('message.cur'),
                            value: 'Currency.code',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 22,
                            text: this.$t('message.cost'),
                            value: 'Contract.cost',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-end',
                            cellClass: 'blue-cell text-end'
                        },
                        {
                            id: 23,
                            text: this.$t('message.deposit'),
                            value: 'SupplierPayment.deposit',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-end',
                            cellClass: 'blue-cell text-end'
                        },
                        {
                            id: 24,
                            text: this.$t('message.balance'),
                            value: 'SupplierPayment.outstanding',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-end',
                            cellClass: 'blue-cell text-end'
                        },
                        {
                            id: 25,
                            text: this.$t('message.dueDate'),
                            value: 'SupplierPayment.duedate',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 26,
                            text: this.$t('message.paid'),
                            value: 'SupplierPayment.paymentdate',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                    ]
                    break
                case 'wh':
                    headers = [
                        {
                            id: 1,
                            text: this.$t('message.contractPo'),
                            value: 'Contract.title',
                            sortable: true,
                            searchable: true,
                            class: 'py-0 pr-0 pl-1 font-sm text-left'
                        },
                        {
                            id: 2,
                            text: this.$t('message.supplier'),
                            value: 'Supplier.name',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 3,
                            text: this.$t('message.invoice'),
                            value: 'Contract.supplier_pi_no',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 4,
                            text: this.$t('message.date'),
                            value: 'Contract.supplier_pi_date',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 5,
                            text: this.$t('message.terms'),
                            value: 'Contract.type_insurance',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 6,
                            text: this.$t('message.supplierTerms'),
                            value: 'SupplierPayment.status',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 7,
                            text: this.$t('message.etd'),
                            value: 'Contract.etddate',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 8,
                            text: this.$t('message.eta'),
                            value: 'Contract.etadate',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        // {
                        //     id: 8,
                        //     text: this.$t('message.blDate'),
                        //     value: 'Contract.bldate',
                        //     sortable: true,
                        //     searchable: true,
                        //     class: 'blue lighten-3 py-0 pr-0 pl-1 font-sm text-left'
                        // },
                        {
                            id: 9,
                            text: this.$t('message.dueDate'),
                            value: 'SupplierPayment.duedate',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 10,
                            text: this.$t('message.cur'),
                            value: 'Currency.code',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 11,
                            text: this.$t('message.cost'),
                            value: 'Contract.cost',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 12,
                            text: this.$t('message.deposit'),
                            value: 'SupplierPayment.deposit',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 13,
                            text: this.$t('message.balance'),
                            value: 'SupplierPayment.outstanding',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                        {
                            id: 14,
                            text: this.$t('message.paid'),
                            value: 'SupplierPayment.paymentdate',
                            sortable: true,
                            searchable: true,
                            class: 'blue lighten-4 py-0 pr-0 pl-1 font-sm text-left',
                            cellClass: 'blue-cell'
                        },
                    ]
                    break
            }
            return headers
        }
    },
    watch: {
        load: {
            handler(val){
                if(val) {
                    this.loadContractSchedule()
                        .then(() => {
                            this.$emit('contract-schedule-loaded')
                        })
                        .catch(() => {
                            this.$emit('contract-schedule-loaded')
                        })
                }
            }
        },
        resetExportParam(val){
            if(val){
                let conditions = []
                let officeId = 1
                switch (this.office){
                    case 'ltd':
                        let condition = {
                            field: 'contracts.office_id',
                            value: 3
                        }
                        officeId = 3
                        conditions.push(condition)
                        break;
                    case 'my':
                        condition = {
                            field: 'contracts.office_id',
                            value: 1
                        }
                        officeId = 1
                        conditions.push(condition)
                        break;
                    case 'id':
                        condition = {
                            field: 'contracts.office_id',
                            value: 21
                        }
                        officeId = 21
                        conditions.push(condition)
                        break;
                    case 'th':
                        condition = {
                            field: 'contracts.office_id',
                            value: 20
                        }
                        officeId = 20
                        conditions.push(condition)
                        break;
                    case 'vn':
                        condition = {
                            field: 'contracts.office_id',
                            value: 22
                        }
                        officeId = 22
                        conditions.push(condition)
                        break;
                }
                switch (this.salesType){
                    case 'ds':
                        let condition = {
                            field: 'contracts.salestype_id',
                            value: [1]
                        }
                        conditions.push(condition)
                        break
                    case 'ds-ss':
                        condition = {
                            field: 'contracts.salestype_id',
                            value: [1,5,99]
                        }
                        conditions.push(condition)
                        break
                    case 'ss':
                        condition = {
                            field: 'contracts.salestype_id',
                            value: [5]
                        }
                        conditions.push(condition)
                        break
                    case 'wh':
                        condition = {
                            field: 'contracts.salestype_id',
                            value: [3]
                        }
                        conditions.push(condition)
                        break
                }
                if(this.filters.year != null){
                    let condition = {
                        field: 'filterYear',
                        value: this.filters.year
                    }
                    conditions.push(condition)
                }
                if(this.filters.monthFrom != null){
                    let condition = {
                        field: 'filterMonthFrom',
                        value: this.filters.monthFrom
                    }
                    conditions.push(condition)
                }
                if(this.filters.monthTo != null){
                    let condition = {
                        field: 'filterMonthTo',
                        value: this.filters.monthTo
                    }
                    conditions.push(condition)
                }
                if(this.filters.paymentStatus){
                    let condition = {
                        field: 'paymentStatus',
                        value: this.filters.paymentStatus
                    }
                    conditions.push(condition)
                }
                this.exportContractScheduleParams = {conditions: conditions}
            }
        }
    },
    methods: {
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        handleResize() {
            this.tableHeight = window.innerHeight - (325);
        },
        loadContractSchedule() {
            let conditions = []
            let officeId = 1
            switch (this.office){
                case 'ltd':
                    let condition = {
                        field: 'contracts.office_id',
                        value: 3
                    }
                    officeId = 3
                    conditions.push(condition)
                    break;
                case 'my':
                    condition = {
                        field: 'contracts.office_id',
                        value: 1
                    }
                    officeId = 1
                    conditions.push(condition)
                    break;
                case 'id':
                    condition = {
                        field: 'contracts.office_id',
                        value: 21
                    }
                    officeId = 21
                    conditions.push(condition)
                    break;
                case 'th':
                    condition = {
                        field: 'contracts.office_id',
                        value: 20
                    }
                    officeId = 20
                    conditions.push(condition)
                    break;
                case 'vn':
                    condition = {
                        field: 'contracts.office_id',
                        value: 22
                    }
                    officeId = 22
                    conditions.push(condition)
                    break;
            }
            switch (this.salesType){
                case 'ds':
                    let condition = {
                        field: 'contracts.salestype_id',
                        value: [1]
                    }
                    conditions.push(condition)
                    break
                case 'ds-ss':
                    condition = {
                        field: 'contracts.salestype_id',
                        value: [1,5,99]
                    }
                    conditions.push(condition)
                    break
                case 'ss':
                    condition = {
                        field: 'contracts.salestype_id',
                        value: [5]
                    }
                    conditions.push(condition)
                    break
                case 'wh':
                    condition = {
                        field: 'contracts.salestype_id',
                        value: [3]
                    }
                    conditions.push(condition)
                    break
            }
            if(this.filters.year != null){
                let condition = {
                    field: 'filterYear',
                    value: this.filters.year
                }
                conditions.push(condition)
            }
            if(this.filters.monthFrom != null){
                let condition = {
                    field: 'filterMonthFrom',
                    value: this.filters.monthFrom
                }
                conditions.push(condition)
            }
            if(this.filters.monthTo != null){
                let condition = {
                    field: 'filterMonthTo',
                    value: this.filters.monthTo
                }
                conditions.push(condition)
            }
            if(this.filters.paymentStatus){
                let condition = {
                    field: 'paymentStatus',
                    value: this.filters.paymentStatus
                }
                conditions.push(condition)
            }
            return new Promise((resolve, reject) => {
                api
                    .get('/reports/finance/contract-schedule/' + officeId,{
                        params: {
                            conditions: conditions
                        }
                    })
                    .then(response => {
                        this.items = response.data.data
                        this.exportContractScheduleParams = { conditions: conditions }
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        exportTableToExcel() {
            this.$toast.success(this.$t('message.successes.exportExcelPleaseWaitForDownload'),
                {
                    timeout: 3000,
                    color: 'info',
                    classes: ['icon-float-left'],
                    icon: 'check_circle_outline',
                    x: 'centered',
                    y: 'top'
                }
            )
        },
        exportTableToPdf() {
            this.$toast.success(this.$t('message.successes.exportPdfPleaseWaitForDownload'),
                {
                    timeout: 3000,
                    color: 'info',
                    classes: ['icon-float-left'],
                    icon: 'check_circle_outline',
                    x: 'centered',
                    y: 'top'
                }
            )
        },
        filterResults() {},
        // searchContracts () {},
        updateExportParams() {
            let conditions = []
            let officeId = 1
            switch (this.office){
                case 'ltd':
                    let condition = {
                        field: 'contracts.office_id',
                        value: 3
                    }
                    officeId = 3
                    conditions.push(condition)
                    break;
                case 'my':
                    condition = {
                        field: 'contracts.office_id',
                        value: 1
                    }
                    officeId = 1
                    conditions.push(condition)
                    break;
                case 'id':
                    condition = {
                        field: 'contracts.office_id',
                        value: 21
                    }
                    officeId = 21
                    conditions.push(condition)
                    break;
                case 'th':
                    condition = {
                        field: 'contracts.office_id',
                        value: 20
                    }
                    officeId = 20
                    conditions.push(condition)
                    break;
                case 'vn':
                    condition = {
                        field: 'contracts.office_id',
                        value: 22
                    }
                    officeId = 22
                    conditions.push(condition)
                    break;
            }
            switch (this.salesType){
                case 'ds':
                    let condition = {
                        field: 'contracts.salestype_id',
                        value: [1]
                    }
                    conditions.push(condition)
                    break
                case 'ds-ss':
                    condition = {
                        field: 'contracts.salestype_id',
                        value: [1,5,99]
                    }
                    conditions.push(condition)
                    break
                case 'ss':
                    condition = {
                        field: 'contracts.salestype_id',
                        value: [5]
                    }
                    conditions.push(condition)
                    break
                case 'wh':
                    condition = {
                        field: 'contracts.salestype_id',
                        value: [3]
                    }
                    conditions.push(condition)
                    break
            }
            if(this.filters.year != null){
                let condition = {
                    field: 'filterYear',
                    value: this.filters.year
                }
                conditions.push(condition)
            }
            if(this.filters.monthFrom != null){
                let condition = {
                    field: 'filterMonthFrom',
                    value: this.filters.monthFrom
                }
                conditions.push(condition)
            }
            if(this.filters.monthTo != null){
                let condition = {
                    field: 'filterMonthTo',
                    value: this.filters.monthTo
                }
                conditions.push(condition)
            }
            if(this.filters.paymentStatus){
                let condition = {
                    field: 'paymentStatus',
                    value: this.filters.paymentStatus
                }
                conditions.push(condition)
            }
            this.exportContractScheduleParams = { conditions: conditions }
        }
    },
    created () {
        window.addEventListener('resize', this.handleResize)
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        this.loadContractSchedule()
            .then(() => {
                this.$emit('contract-schedule-loaded')
            })
            .catch(() => {
                this.$emit('contract-schedule-loaded')
            })
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 2px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table th span {
    font-family: 'Arial Narrow', Arial, SansSerif;
    font-size: 0.8rem !important;
    font-weight: normal;
    line-height: 0.8rem;
    font-weight: bold !important;
}
.v-data-table-header th {
    white-space: nowrap;
}
.green-cell {
    background-color: #C5E1A5;
}
.blue-cell {
    background-color: #BBDEFB;
}
</style>